import React from 'react';
import { PageProps } from 'gatsby';
import { WithTranslation, Trans, withTranslation } from 'react-i18next';

import Layout, { LayoutOptions, HeroSectionColors } from '../../../components/Layout/Layout';
import { LocaleNameSpaceKeys } from '../../../localization/translations';
import PageMenu from '../../../components/PageMenu/PageMenu';
import mortgageResourcesMenu from '../../../data/page-menus/mortgage-resources';
import ButtonLink from '../../../components/ButtonLink/ButtonLink';
import { getPathFromRouteKey } from '../../../shared/utils/getPathFromRouteKey';
import RouteKeys from '../../../../config/RouteKeys';
import { IconTypes } from '../../../components/Icon/Icon';
import ExternalLink from '../../../components/ExternalLink/ExternalLink';
import CommonData from '../../../data/common/common';
import Link from '../../../components/Link/Link';
import { useLocaleContext } from '../../../context/Locale';
import './style.scss';

type Props = PageProps & WithTranslation;

const UnderstandingYourMortgage: React.FC<Props> = (props: Props) => {
  const { t } = props;
  const { activeLocaleTag } = useLocaleContext();

  const layoutOptions: LayoutOptions = {
    seo: {
      title: t('understandingYourMortgage.seo.title'),
    },
    page: {
      title: t('understandingYourMortgage.heading'),
    },
    heroSection: {
      options: {
        heroImage: {
          fileName: 'hero-image-13.jpg',
          alt: 'Hero Image',
        },
        accentColor: HeroSectionColors.SKY_BLUE,
      },
    },
  };

  const quickLinks = (() => {
    const links: any = t('understandingYourMortgage.quickLinks', { returnObjects: true });

    return [
      ...Object.keys(links).map((objKey: any) => ({
        href: `#${links[objKey].id}`,
        label: `${links[objKey].label}`,
      })),
      ...(t('understandingYourMortgage.externalLinks', {
        returnObjects: true,
      }) as any[]).map((link: any) => ({
        href: (CommonData.websites as any)[link.id][activeLocaleTag],
        label: `${link.label}`,
        additionalProps: {
          target: '_blank',
          rel: 'noopener noreferer',
        },
      })),
    ];
  })();

  // const externalLinks = (t('understandingYourMortgage.externalLinks', {
  //   returnObjects: true,
  // }) as any[]).map((link: any) => ({
  //   href: (CommonData.websites as any)[link.id][activeLocaleTag],
  //   label: `${link.label}`,
  //   additionalProps: {
  //     target: '_blank',
  //     rel: 'noopener noreferer',
  //   },
  // }));

  const getQuickLinks = (links: any[]) => {
    return links.map((link) => {
      return (
        <a key={link.href} href={`${link.href}`} {...link.additionalProps}>
          {link.label}
        </a>
      );
    });
  };

  const getQuickLinksColumn = (links: any[]) => {
    const halfLength = Math.ceil(links.length / 2);
    const firstHalf = links.slice(0, halfLength);
    const secondHalf = links.slice(halfLength, links.length);
    const columns = [];
    if (firstHalf) {
      columns.push(
        <div
          key="01-block"
          className="column column-50 UnderstandingYourMortgage__quick-links-block"
        >
          {getQuickLinks(firstHalf)}
        </div>
      );
    }

    if (secondHalf) {
      columns.push(
        <div
          key="02-block"
          className="column column-50 UnderstandingYourMortgage__quick-links-block"
        >
          {getQuickLinks(secondHalf)}
        </div>
      );
    }

    return columns;
  };

  const generateList = (list: string[]) => {
    return (
      <ul>
        {list.map((str, i) => {
          // eslint-disable-next-line react/no-danger
          return <li key={`${str}-${i}`} dangerouslySetInnerHTML={{ __html: str }} />;
        })}
      </ul>
    );
  };

  const downloadBase = '/downloads/mortgage-servicing-documents/';

  const feeScheduleDownloadLink = {
    en: `${downloadBase}Haventree_ServicingPrice2024(22May2024).pdf`,
    fr: `${downloadBase}Haventree_BaremeDeFrais2024(22Mai2024).pdf`,
  };

  const currentFeeScheduleLink = feeScheduleDownloadLink[activeLocaleTag];

  return (
    <Layout options={layoutOptions} className="UnderstandingYourMortgage">
      <div className="grid-container ">
        <div className="row row-wrap UnderstandingYourMortgage__generic-gap">
          <section className="column column-60">
            <p className="UnderstandingYourMortgage__body-generic-gap">
              {t('understandingYourMortgage.introParagraph')}
            </p>
            <div className="row row-wrap UnderstandingYourMortgage__quick-links-wrapper">
              {getQuickLinksColumn(quickLinks)}
            </div>
            <div className="UnderstandingYourMortgage__generic-gap">
              <p className="UnderstandingYourMortgage__body-generic-gap">
                {t('understandingYourMortgage.feeSchedule.description')}
              </p>
              <div className="UnderstandingYourMortgage__mortgage-calculator-button">
                <ButtonLink
                  linkOptions={{
                    isExternalLink: true,
                  }}
                  to={currentFeeScheduleLink}
                  styleOptions={{ isInline: true }}
                  iconOptions={{ icon: IconTypes.ARROW_NEXT }}
                >
                  {t('understandingYourMortgage.feeSchedule.buttonLabel')}
                </ButtonLink>
              </div>
            </div>
          </section>
          <aside className="column column-33">
            <PageMenu
              menu={mortgageResourcesMenu}
              menuTitle={t(`${LocaleNameSpaceKeys.COMMON}:pageMenuTitles.mortgageResources`)}
            />
          </aside>
        </div>

        <section
          id={t('understandingYourMortgage.quickLinks.fixedVariableRateMortgages.id')}
          className="UnderstandingYourMortgage__generic-gap"
        >
          <h2 className="UnderstandingYourMortgage__list-main-heading">
            {t('understandingYourMortgage.fixedVariableRateMortgages.heading')}
          </h2>
          <div className="row row-wrap">
            <div className="column column-45">
              <h3>{t('understandingYourMortgage.fixedVariableRateMortgages.listLeft.heading')}</h3>
              {generateList(
                t('understandingYourMortgage.fixedVariableRateMortgages.listLeft.list', {
                  returnObjects: true,
                })
              )}
            </div>
            <div className="column column-45">
              <h3>{t('understandingYourMortgage.fixedVariableRateMortgages.listRight.heading')}</h3>
              {generateList(
                t('understandingYourMortgage.fixedVariableRateMortgages.listRight.list', {
                  returnObjects: true,
                })
              )}
            </div>
          </div>
        </section>

        <section
          id={t('understandingYourMortgage.quickLinks.longShortTermMortgage.id')}
          className="UnderstandingYourMortgage__generic-gap"
        >
          <h2 className="UnderstandingYourMortgage__list-main-heading">
            {t('understandingYourMortgage.longShortTermMortgage.heading')}
          </h2>
          <div className="row row-wrap">
            <div className="column column-45">
              <h3>{t('understandingYourMortgage.longShortTermMortgage.listLeft.heading')}</h3>
              {generateList(
                t('understandingYourMortgage.longShortTermMortgage.listLeft.list', {
                  returnObjects: true,
                })
              )}
            </div>
            <div className="column column-45">
              <h3>{t('understandingYourMortgage.longShortTermMortgage.listRight.heading')}</h3>
              {generateList(
                t('understandingYourMortgage.longShortTermMortgage.listRight.list', {
                  returnObjects: true,
                })
              )}
            </div>
          </div>
        </section>

        <section
          id={t('understandingYourMortgage.quickLinks.openClosedTermMortgage.id')}
          className="UnderstandingYourMortgage__generic-gap"
        >
          <h2 className="UnderstandingYourMortgage__list-main-heading">
            {t('understandingYourMortgage.openClosedTermMortgage.heading')}
          </h2>
          <div className="row row-wrap">
            <div className="column column-45">
              <h3>{t('understandingYourMortgage.openClosedTermMortgage.listLeft.heading')}</h3>
              {generateList(
                t('understandingYourMortgage.openClosedTermMortgage.listLeft.list', {
                  returnObjects: true,
                })
              )}
            </div>
            <div className="column column-45">
              <h3>{t('understandingYourMortgage.openClosedTermMortgage.listRight.heading')}</h3>
              {generateList(
                t('understandingYourMortgage.openClosedTermMortgage.listRight.list', {
                  returnObjects: true,
                })
              )}
            </div>
          </div>
        </section>

        <section
          id={t('understandingYourMortgage.quickLinks.prepaymentOptions.id')}
          className="UnderstandingYourMortgage__generic-gap"
        >
          <h2 className="UnderstandingYourMortgage__list-main-heading">
            {t('understandingYourMortgage.prepaymentOptions.heading')}
          </h2>
          <p>{t('understandingYourMortgage.prepaymentOptions.description')}</p>
          <div className="row row-wrap">
            <div className="column column-100">
              <h3>{t('understandingYourMortgage.prepaymentOptions.listLeft.heading')}</h3>
              <p>{t('understandingYourMortgage.prepaymentOptions.listLeft.description')}</p>
              {generateList(
                t('understandingYourMortgage.prepaymentOptions.listLeft.list', {
                  returnObjects: true,
                })
              )}
            </div>
          </div>
          <p>{t('understandingYourMortgage.prepaymentOptions.footerDescription')}</p>
          <p className="small">
            <em>{t('understandingYourMortgage.prepaymentOptions.footerNote')}</em>
          </p>
        </section>

        <section
          id={t('understandingYourMortgage.quickLinks.prepaymentCharges.id')}
          className="UnderstandingYourMortgage__generic-gap"
        >
          <h2 className="UnderstandingYourMortgage__list-main-heading">
            {t('understandingYourMortgage.prepaymentCharges.heading')}
          </h2>
          <div>
            <h3>{t('understandingYourMortgage.prepaymentCharges.listOne.heading')}</h3>
            {generateList(
              t('understandingYourMortgage.prepaymentCharges.listOne.list', {
                returnObjects: true,
              })
            )}
          </div>
          <div className="row row-wrap">
            <div className="column column-100">
              <h3>{t('understandingYourMortgage.prepaymentCharges.listLeft.heading')}</h3>
              <ul>
                <li>
                  <Trans
                    i18nKey="understandingYourMortgage.prepaymentCharges.listLeft.listItem"
                    t={t}
                  >
                    <Link
                      to={getPathFromRouteKey(RouteKeys.CUSTOMER_CENTRE.PREPAYMENT_CALCULATOR)}
                    />
                  </Trans>
                </li>
              </ul>
            </div>
          </div>
        </section>

        <section className="UnderstandingYourMortgage__generic-gap UnderstandingYourMortgage__mortgage-calculator-button">
          <ButtonLink
            to={getPathFromRouteKey(RouteKeys.CUSTOMER_CENTRE.PREPAYMENT_CALCULATOR)}
            styleOptions={{ isInline: true }}
            iconOptions={{ icon: IconTypes.ARROW_NEXT }}
          >
            {t('understandingYourMortgage.mortgagePrepaymentCalculatorLabel')}
          </ButtonLink>
        </section>

        <section
          id={t('understandingYourMortgage.quickLinks.reducingYourPrepaymentCharges.id')}
          className="UnderstandingYourMortgage__generic-gap"
        >
          <h2 className="UnderstandingYourMortgage__list-main-heading">
            {t('understandingYourMortgage.reducingYourPrepaymentCharges.heading')}
          </h2>
          <div className="row row-wrap">
            <div className="column column-45">
              <p> {t('understandingYourMortgage.reducingYourPrepaymentCharges.description')}</p>
            </div>
            <div className="column column-45 UnderstandingYourMortgage__body-generic-gap">
              {generateList(
                t('understandingYourMortgage.reducingYourPrepaymentCharges.list', {
                  returnObjects: true,
                })
              )}
            </div>
            <div className="column column-100 UnderstandingYourMortgage__body-generic-gap">
              <h3>
                {t(
                  'understandingYourMortgage.reducingYourPrepaymentCharges.estimateCostOfThreeMonths.heading'
                )}
              </h3>
              <h4>
                {t(
                  'understandingYourMortgage.reducingYourPrepaymentCharges.estimateCostOfThreeMonths.step1.heading'
                )}
              </h4>
              {generateList(
                t(
                  'understandingYourMortgage.reducingYourPrepaymentCharges.estimateCostOfThreeMonths.step1.steps',
                  {
                    returnObjects: true,
                  }
                )
              )}

              <h4>
                {t(
                  'understandingYourMortgage.reducingYourPrepaymentCharges.estimateCostOfThreeMonths.step2.heading'
                )}
              </h4>
              {generateList(
                t(
                  'understandingYourMortgage.reducingYourPrepaymentCharges.estimateCostOfThreeMonths.step2.steps',
                  {
                    returnObjects: true,
                  }
                )
              )}
            </div>

            <div className="column column-100">
              <h3>
                {t(
                  'understandingYourMortgage.reducingYourPrepaymentCharges.estimateCostOfInterestRateDifferentials.heading'
                )}
              </h3>
              <h4>
                {t(
                  'understandingYourMortgage.reducingYourPrepaymentCharges.estimateCostOfInterestRateDifferentials.step1.heading'
                )}
              </h4>
              {generateList(
                t(
                  'understandingYourMortgage.reducingYourPrepaymentCharges.estimateCostOfInterestRateDifferentials.step1.steps',
                  {
                    returnObjects: true,
                  }
                )
              )}

              <h4>
                {t(
                  'understandingYourMortgage.reducingYourPrepaymentCharges.estimateCostOfInterestRateDifferentials.step2.heading'
                )}
              </h4>
              {generateList(
                t(
                  'understandingYourMortgage.reducingYourPrepaymentCharges.estimateCostOfInterestRateDifferentials.step2.steps',
                  {
                    returnObjects: true,
                  }
                )
              )}
            </div>
          </div>
        </section>

        <section
          id={t('understandingYourMortgage.quickLinks.factorsAffectingPrepaymentCharges.id')}
          className="UnderstandingYourMortgage__generic-gap"
        >
          <h2 className="UnderstandingYourMortgage__list-main-heading">
            {t('understandingYourMortgage.factorsAffectingPrepaymentCharges.heading')}
          </h2>
          <div className="row row-wrap">
            <div className="column column-100 UnderstandingYourMortgage__body-generic-gap">
              <h3>
                {t('understandingYourMortgage.factorsAffectingPrepaymentCharges.listA.heading')}
              </h3>
              {generateList(
                t('understandingYourMortgage.factorsAffectingPrepaymentCharges.listA.list', {
                  returnObjects: true,
                })
              )}
            </div>
            <div className="column column-100">
              <h3>
                {t('understandingYourMortgage.factorsAffectingPrepaymentCharges.listB.heading')}
              </h3>
              {generateList(
                t('understandingYourMortgage.factorsAffectingPrepaymentCharges.listB.list', {
                  returnObjects: true,
                })
              )}
            </div>
          </div>
        </section>

        <section
          id={t('understandingYourMortgage.quickLinks.needMoreInformation.id')}
          className="UnderstandingYourMortgage__generic-gap"
        >
          <h2 className="UnderstandingYourMortgage__list-main-heading">
            {t('understandingYourMortgage.needMoreInformation.heading')}
          </h2>
          <p>
            <Trans
              i18nKey="understandingYourMortgage.needMoreInformation.description"
              t={t}
              values={{
                tollFreeNumber: CommonData.tollFreeCustomerServiceNumber.mortgageServicing,
              }}
            >
              <ExternalLink href={CommonData.websites.fcacMortgages[activeLocaleTag]} />
              <ExternalLink href={CommonData.websites.cbaMortgages[activeLocaleTag]} />
              <ExternalLink
                href={`tel:${CommonData.tollFreeCustomerServiceNumber.mortgageServicing}`}
                aria-label={`${t(`${LocaleNameSpaceKeys.COMMON}:tollFree`)} ${
                  CommonData.tollFreeCustomerServiceNumber.mortgageServicing
                }`}
              />
            </Trans>
          </p>
        </section>
      </div>
    </Layout>
  );
};

export default withTranslation(LocaleNameSpaceKeys.CUSTOMER_CENTRE)(UnderstandingYourMortgage);
